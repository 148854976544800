export const powerCountersDefault = {
    hero: { water: 0, forest: 0, mountain: 0 },
    companion: { water: 0, forest: 0, mountain: 0 }
}

export const powerCountersActiveDefault = {
    hero: { water: true, forest: true, mountain: true },
    companion: { water: true, forest: true, mountain: true }
}

export const tumulteCardsData = {
    "tumulte-1-1": {
        image: "tumulte-1-1.png",
        activeBiomes: [
            { water: false, forest: true, mountain: false },
            { water: true, forest: false, mountain: true }
        ]
    },
    "tumulte-1-2": {
        image: "tumulte-1-2.png",
        activeBiomes: [
            { water: true, forest: false, mountain: true },
            { water: false, forest: true, mountain: false }
        ]
    },
    "tumulte-2-1": {
        image: "tumulte-2-1.png",
        activeBiomes: [
            { water: true, forest: false, mountain: false },
            { water: false, forest: true, mountain: true }
        ]
    },
    "tumulte-2-2": {
        image: "tumulte-2-2.png",
        activeBiomes: [
            { water: false, forest: true, mountain: true },
            { water: true, forest: false, mountain: false }
        ]
    },
    "tumulte-3-1": {
        image: "tumulte-3-1.png",
        activeBiomes: [
            { water: false, forest: false, mountain: true },
            { water: true, forest: true, mountain: false }
        ]
    },
    "tumulte-3-2": {
        image: "tumulte-3-2.png",
        activeBiomes: [
            { water: true, forest: true, mountain: false },
            { water: false, forest: false, mountain: true }
        ]
    },
    "2v2-tumulte-1-1": {
        image: "2v2-tumulte-1-1.png",
        activeBiomes: [
            { water: false, forest: false, mountain: true },
            { water: true, forest: true, mountain: false }
        ]
    },
    "2v2-tumulte-1-2": {
        image: "2v2-tumulte-1-2.png",
        activeBiomes: [
            { water: true, forest: true, mountain: false },
            { water: false, forest: false, mountain: true }
        ]
    },
    "2v2-tumulte-2-1": {
        image: "2v2-tumulte-2-1.png",
        activeBiomes: [
            { water: false, forest: true, mountain: false },
            { water: true, forest: false, mountain: true }
        ]
    },
    "2v2-tumulte-2-2": {
        image: "2v2-tumulte-2-2.png",
        activeBiomes: [
            { water: true, forest: false, mountain: true },
            { water: false, forest: true, mountain: false }
        ]
    },
    "2v2-tumulte-3-1": {
        image: "2v2-tumulte-3-1.png",
        activeBiomes: [
            { water: true, forest: false, mountain: false },
            { water: false, forest: true, mountain: true }
        ]
    },
    "2v2-tumulte-3-2": {
        image: "2v2-tumulte-3-2.png",
        activeBiomes: [
            { water: false, forest: true, mountain: true },
            { water: true, forest: false, mountain: false }
        ]
    }
}

export const pseudoDefault = [
    "Ada",
    "Coppelia",
    "Jian",
    "Ogun",
    "Amelia",
    "Ratatosk",
    "Chiron",
    "Esmeralda",
    "Anansi",
    "Hathor",
    "Inari",
    "Aja",
    "Kakoba",
    "Ozma",
    "Kuwat",
    "Sierra",
    "Oddball",
    "Subhash",
    "Marmo",
    "Treyst",
    "Rossum",
    "Kojo",
    "Booda",
    "Atsadi",
    "Surge",
    "Basira",
    "Kaizaimon",
    "Nevenka",
    "Blotch",
    "Auraq",
    "Crowbar",
    "Teija",
    "Nauraa",
    "Arjun",
    "Spike",
    "Rin",
    "Orchid",
    "Sigismar",
    "Wingspan",
    "Gulrang",
    "Tocsin",
    "Waru",
    "Mack",
    "Akesha",
    "Taru",
    "Afanas",
    "Senka",
    "Lindiwe",
    "Maw",
]


export const virtualBoardDefault = {
    deckSize: 0,
    handSize: 0,
    hero: false,
    heroExpedition: [],
    companionExpedition: [],
    reserve: [],
    landmarks: [],
    mana: [],
    discard: [],
    lastPlayedCard: false,
    backgroundChoice: 1,
    hand: [],
    source: "deck-tester",
    deck: [],
    deckList: [],
    showEvening: false
}