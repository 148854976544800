export default async function getCardImageUrl(reference) {
    console.log("CALLING API")
    //let split = cardName.split("/")
    //let cardNameCut = split[split.length - 1]
    let url = "https://api.altered.gg/cards?reference=" + reference
// https://api.altered.gg/cards?reference=ALT_CORE_B_BR_07_R2
    const reponse = await fetch(url);
    const cardResponse = await reponse.json();
    let cards = cardResponse['hydra:member']
    if (cards.length == 0) {
        return undefined
    }
    let card = cards[0]
    if (card == undefined) {
        return undefined
    }
    return card['imagePath']
}

function getCardImageUrl_old(cardName) {
    let cardNameCut = cardName.replace("https://www.altered.gg/fr-fr/cards/", "")
    if (cardName.includes("ALT_CORE_B_AX")) {
        return getCard_ALT_CORE_B_AX(cardNameCut)
    } else if (cardName.includes("ALT_CORE_B_BR")) {
        return getCardBravos(cardNameCut)
    } else if (cardName.includes("ALT_CORE_B_LY")) {
        return getCardLyra(cardNameCut)
    } else if (cardName.includes("ALT_CORE_B_MU")) {
        return getCardMuna(cardNameCut)
    } else if (cardName.includes("ALT_CORE_B_OR")) {
        return getCardOrdis(cardNameCut)
    } else if (cardName.includes("ALT_CORE_B_YZ")) {
        return getCardYzmir(cardNameCut)
    }
}

function getCard_RZ(cardName) {
    switch (cardName) {
        case "ALT_CORE_B_BR_07_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_BR_07/JPG/en_US/2476f1fdddf9bf314563dc721c4623e4.jpg"
            break;
        case "ALT_CORE_B_BR_14_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_BR_14/JPG/en_US/0407b11f5a2e455a0e407d67ef4a21c7.jpg"
            break;
        case "ALT_CORE_B_BR_15_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_BR_15/JPG/en_US/55c6620eec33dfdbbc82357282f4e68a.jpg"
            break;
        case "ALT_CORE_B_BR_30_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_BR_30/JPG/en_US/3a3176c93887b2d57d845dd9423aca91.jpg"
            break;
        case "ALT_CORE_B_LY_10_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_10/JPG/en_US/245dbcd1f7b7f4df98bec4b46d078fdc.jpg"
            break;
        case "ALT_CORE_B_LY_16_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_16/JPG/en_US/d8f7b92d589a96e4121458a7af79d36f.jpg"
            break;
        case "ALT_CORE_B_LY_17_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_17/JPG/en_US/454bdf00c89085420d1ac1d6e96b5687.jpg"
            break;
        case "ALT_CORE_B_LY_19_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_19/JPG/en_US/4707c2c00dfd1cad7e765b978a98cebb.jpg"
            break;
        case "ALT_CORE_B_LY_30_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_LY_30/JPG/en_US/28cecb5043083b18ef076e078e043428.jpg"
            break;
        case "ALT_CORE_B_MU_11_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_11/JPG/en_US/af5e8f32ec8aa78e9ee754eab0df2449.jpg"
            break;
        case "ALT_CORE_B_MU_12_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_12/JPG/en_US/8d18661567d013c0528ffa7e5509d7c3.jpg"
            break;
        case "ALT_CORE_B_MU_13_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_MU_13/JPG/en_US/c5fc0cf39c5fc8a76e03047e3fc8b3ee.jpg"
            break;
        case "ALT_CORE_B_OR_07_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_07/JPG/en_US/6d7971989797385dfbd4873b7ff4e12f.jpg"
            break;
        case "ALT_CORE_B_OR_09_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_09/JPG/en_US/8e06291a98466210808e56ac5b48032d.jpg"
            break;
        case "ALT_CORE_B_OR_17_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_17/JPG/en_US/96c86c0a7e692d912c517191b9b86a09.jpg"
            break;
        case "ALT_CORE_B_OR_25_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_25/JPG/en_US/e6b222f2af546560ac987d01de4302c8.jpg"
            break;
        case "ALT_CORE_B_OR_26_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_OR_26/JPG/en_US/bf89e1c89a27738022b9c23e112f6903.jpg"
            break;
        case "ALT_CORE_B_YZ_05_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_05/JPG/en_US/e248d53b204ce0657ca8b48eaa4a5897.jpg"
            break;
        case "ALT_CORE_B_YZ_08_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_08/JPG/en_US/e672b18216c9631d776535f60ba6cb01.jpg"
            break;
        case "ALT_CORE_B_YZ_26_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_YZ_26/JPG/en_US/d1b313f1207815924841da8bd9108e98.jpg"
            break;
    }
}

function getCard_ALT_CORE_B_AX(cardName) {
    switch (cardName) {
        case "ALT_CORE_B_AX_01_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_01/JPG/en_US/e9cf4439f25391d9fc21300cce1bbe44.jpg"
            break;
        case "ALT_CORE_B_AX_02_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_02/JPG/en_US/e8d562c8402df1d8ba79d70139706d11.jpg"
            break;
        case "ALT_CORE_B_AX_03_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_03/JPG/en_US/05ba21398ad2dce1eb30beccc957a1c2.jpg"
            break;
        case "ALT_CORE_B_AX_04_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_04/JPG/en_US/fca50447c3db9ed73203afbacdd8cabf.jpg"
            break;
        case "ALT_CORE_B_AX_4_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_04/JPG/en_US/4187ecad6c030eae62446f153489f11e.jpg"
            break;
        case "ALT_CORE_B_AX_04_R2":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_04/JPG/en_US/cff8936711c411ea60dcc47433a94616.jpg"
            break;
        case "ALT_CORE_B_AX_07_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_07/JPG/en_US/c00690f154c2173bc26f6802812f5f3f.jpg"
            break;
        case "ALT_CORE_B_AX_07_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_07/JPG/en_US/7afba116182d4c7d95beaad7b8a4659f.jpg"
            break;
        case "ALT_CORE_B_AX_08_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_08/JPG/en_US/3f97f3f54502ce702524e05aed7774a5.jpg"
            break;
        case "ALT_CORE_B_AX_08_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_08/JPG/en_US/389b02630b427344972f8ffc1ddc61f8.jpg"
            break;
        case "ALT_CORE_B_AX_10_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_10/JPG/en_US/8a71802fe71a6a48e19317c6892d388a.jpg"
            break;
        case "ALT_CORE_B_AX_10_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_10/JPG/en_US/d79c2a5e09e02d48c60d3f756adf1ba7.jpg"
            break;
        case "ALT_CORE_B_AX_11_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_11/JPG/en_US/57757c194241d10245fe2877fd386070.jpg"
            break;
        case "ALT_CORE_B_AX_11_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_11/JPG/en_US/5b395f4de20c210417afc908ecf6ee4c.jpg"
            break;
        case "ALT_CORE_B_AX_12_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_12/JPG/en_US/ba4616a6d4fc186c6924dcb4ec50ff91.jpg"
            break;
        case "ALT_CORE_B_AX_12_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_12/JPG/en_US/ba4f5db97b78e2fbbf905230032e9c0e.jpg"
            break;
        case "ALT_CORE_B_AX_13_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_13/JPG/en_US/7104951bbacd35e201ac32a15084d66c.jpg"
            break;
        case "ALT_CORE_B_AX_13_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_13/JPG/en_US/019182f51d9bacc7cab4355f30889d32.jpg"
            break;
        case "ALT_CORE_B_AX_14_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_14/JPG/en_US/1e5ff0c6f05a5204317d052a48d7b2b7.jpg"
            break;
        case "ALT_CORE_B_AX_14_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_14/JPG/en_US/46e6930c1d21b95dcb66852e3323e5ed.jpg"
            break;
        case "ALT_CORE_B_AX_15_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_15/JPG/en_US/2cd5d87a1e31a134a4adcf304d5a67ec.jpg"
            break;
        case "ALT_CORE_B_AX_15_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_15/JPG/en_US/9d20c4fe0f80a8fd59fcbfb3e532a600.jpg"
            break;
        case "ALT_CORE_B_AX_16_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_16/JPG/en_US/52b09611562cb1ece4e551498e196419.jpg"
            break;
        case "ALT_CORE_B_AX_16_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_16/JPG/en_US/63397d924d65ec81e05e09ad0aafb006.jpg"
            break;
        case "ALT_CORE_B_AX_18_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_18/JPG/en_US/2a600b4604cdaa8970a707e7554a4592.jpg"
            break;
        case "ALT_CORE_B_AX_18_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_18/JPG/en_US/0b341dd4ff9e0820aadfd3058290e569.jpg"
            break;
        case "ALT_CORE_B_AX_23_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_23/JPG/en_US/917efaa663dbfe67c758bd2a9a5a1ff2.jpg"
            break;
        case "ALT_CORE_B_AX_23_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_23/JPG/en_US/86f8fb8f2e00afdf3ab27fafbaea7174.jpg"
            break;
        case "ALT_CORE_B_AX_24_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_24/JPG/en_US/051df7dd8dea4f41e2ad9e2dc8cae8f6.jpg"
            break;
        case "ALT_CORE_B_AX_24_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_24/JPG/en_US/a8ee79ddda8a613389331b370ad70300.jpg"
            break;
        case "ALT_CORE_B_AX_25_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_25/JPG/en_US/2fc883285eb7f31bc75481cffe1dadef.jpg"
            break;
        case "ALT_CORE_B_AX_25_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_25/JPG/en_US/215bfd61f1f1fb94935d4c36121b2318.jpg"
            break;
        case "ALT_CORE_B_AX_27_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_27/JPG/en_US/ea759aa0efcf6595d085a114a6b2b6b8.jpg"
            break;
        case "ALT_CORE_B_AX_27_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_27/JPG/en_US/9a0a1b787bc3821588218f69652fc7d6.jpg"
            break;
        case "ALT_CORE_B_AX_30_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_30/JPG/en_US/a881eda7713c62972c326b479fa5376d.jpg"
            break;
        case "ALT_CORE_B_AX_30_R1":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_30/JPG/en_US/86a31a53002e53522cd97a08bf09ca54.jpg"
            break;
        case "ALT_CORE_B_AX_31_C":
            return "https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/ALT_CORE_B_AX_31/JPG/en_US/c67d65a3ce286b238da10aea203e7775.jpg"
            break;
    }
}

function getCardBravos(cardName) {

}

function getCardLyra(cardName) {

}

function getCardMuna(cardName) {

}

function getCardOrdis(cardName) {

}

function getCardYzmir(cardName) {

}