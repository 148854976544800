import React from "react";
import VirtualBoardGameView from "../../VirtualDeck/VirtualBoardGameView";
import VirtualBoard from "../../VirtualDeck/VirtualBoard";

export const ImageMemo = React.memo(function Image({ src, className }) {
    return <img class={className ? className : ""} src={src} />;
});

export const VirtualBoardGameViewMemo = React.memo(({ boardData, zoomVirtualCard, useStreamerLayout, isOpponent, isYourSection, isSpectator, updateBoardData, resetVirtualDeck, newTurnVirtualDeck, changeBackgroundVirtualDeck, useManualMode, isDigitalOnly, useMiniCards }) => {
    return <VirtualBoard 
    boardData={boardData} 
    zoomVirtualCard={zoomVirtualCard} 
    isOpponent={isOpponent} 
    isYourSection={isYourSection} 
    useStreamerLayout={useStreamerLayout} 
    updateBoardData={updateBoardData} 
    resetVirtualDeck={resetVirtualDeck} 
    newTurnVirtualDeck={newTurnVirtualDeck} 
    changeBackgroundVirtualDeck={changeBackgroundVirtualDeck}
    useManualMode={useManualMode}
    isSpectator={isSpectator}
    isDigitalOnly={isDigitalOnly}
    useMiniCards={useMiniCards} 
    />;
});
