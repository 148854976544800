import PageWithNavigation from "../Commons/PageWithNavigation";
import Accordion from 'react-bootstrap/Accordion';
import './About.css'

export default function About() {
    return (
        <PageWithNavigation selected="about" component={
            <div class="w-100 d-flex flex-column about">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h2>Thanks</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div class="d-flex flex-column">
                                <h3>Altered</h3>
                                <p>Thanks to Equinox for making this game.</p>
                                <p>All visual used in this app including images, tumulte cards and hero/companion tokens are property of Equinox.</p>
                                <h3>Icons</h3>
                                <p>Exalts Table icon by max.icons</p>
                                <p>Other icons by Freepik, Kiranshastry, Sonnycandra, Icon mania, Stockio, Roundicons and Ghufronagustian.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <h2>Privacy Policy</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div class="d-flex flex-column">
                                <p>Exalts Table uses peer-to-peer which means your video and your game data is not sent to our server (or stored in it) but directly to your opponent.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h2>About the project</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div class="d-flex flex-column">
                                <p>The goal of this project is to provide a free place for players to play with their physical cards collections with their friends when they can't find the opportunity to meet in person.</p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <h2>About me</h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div class="d-flex flex-column">
                                <p>I'm Loïc (or YellowPiouPiou), a french developper living in Paris. Magic the Gathering lover currently interested in Altered. You can find my Magic the Gathering projects below.</p>
                                <h3>Against the Horde</h3>
                                <p>A cooperative experience for Magic. Team up with your friends againt an automated opponent.</p>
                                <a href="https://against-the-horde.com/">Visit</a>
                                <h3 class="mt-4">Planechase Companion</h3>
                                <p>A Planechase app to easily play with official/custom cards.</p>
                                <a href="https://apps.apple.com/us/app/planechase-companion/id6445894290">Download</a>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        } />
    )
}