export const gameModesInfo = {
    _1v1_standard: {
        modeId: "_1v1_standard",
        title: "1v1 - Standard",
        description: "Standard is the most popular way to play Altered. Standard asks two players to each bring a deck made up of at least 39 cards and 1 Hero from a single faction.",
        players: 2,
        spectators: 0
    },
    _1v1_standard_observer: {
        modeId: "_1v1_standard_observer",
        title: "1v1 - With an observer",
        description: "The other 2 players will be playing a Standard game. The host won't be able to play but will be able to spectate the game. FOR VIRTUAL PLAYERS: BE WARNED THAT THE HOST WILL SEE EVERY PLAYERS HAND.",
        players: 3,
        spectators: 1
    },
    _2v2_standard: {
        modeId: "_2v2_standard",
        title: "2v2 - Standard",
        description: "WIP",
        players: 4,
        spectators: 0
    }
    ,
    _draft: {
        modeId: "_draft",
        title: "Draft - Deckbuild",
        description: "Draft mode for 2 to 8 players. Once the draft is complete and your deck is built, take your decklist and find another lobby to play with it in the game mode of your choice.",
        players: "8",
        spectators: 0
    }
}