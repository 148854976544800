import Footer from "../Home/Footer";
import Navigation from "../Home/Navigation";
import "./PageWithNavigation.css"

export default function PageWithNavigation(props) {
    return (
        <div class="page-with-navigation d-flex flex-column min-height-100 justify-content-between">
            <div class="page-with-navigation-background"></div>
            <div class="w-100 page-with-navigation-content d-flex flex-column justify-content-start">
                <Navigation selected={props.selected} />
                {props.description ? (
                    <div class="description">
                        <p>{props.description}</p>
                    </div>
                ) : (<div class="mt-5"></div>)}
                {props.component}
            </div>
            <Footer />
        </div>
    )
}