import React, { useEffect, useRef, useState } from 'react';
import Peer from 'peerjs';
import QRCode from "react-qr-code";
import QrReader from 'react-qr-scanner'
import './PhoneSetup.css'
import Navigation from '../Home/Navigation';
import PageWithNavigation from '../Commons/PageWithNavigation';
import ErrorMessages from '../Commons/ErrorMessages';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { transformSdp } from './sdp';

export default function PhoneSetup() {
    const [errorMessages, setErrorMessages] = useState([])

    const [peerId, setPeerId] = useState('');
    //const [remotePeerIdValue, setRemotePeerIdValue] = useState('');
    const { remotePeerIdValue } = useParams()
    //const remoteVideoRef = useRef(null);
    const currentUserVideoRef = useRef(null);
    const peerInstance = useRef(null);
    const [startScan, setStartScan] = useState(false)
    const [streamIsActive, setStreamIsActive] = useState(false)
    const thisPeerRef = useRef(null)
    const otherPeerRef = useRef(null)

    const iniPeer = () => {
        const peer = new Peer();

        peer.on('open', (id) => {
            setPeerId(id)
            peer.on("connection", (conn) => {  // listen for data connection here
                console.log("the caller sent message", conn);
                let otherPeerTmp = conn;
                conn.on("data", (data) => {
                    if (data == "end") {
                        conn.close();
                        if (peerInstance.current != null) {
                            peerInstance.current.destroy();
                        }
                        console.log("the caller ended the call", data);
                        setErrorMessages([...errorMessages, "Session ended"])
                        //endCall()
                    }
                    console.log(data)
                });
                otherPeerRef.current = otherPeerTmp
            });
        });

        peerInstance.current = peer;
    }

    useEffect(() => {
        iniPeer()
        if (!remotePeerIdValue) {
            setErrorMessages([...errorMessages, "No id"])
        }
    }, [])

    const sendKeepAlive = () => {
        try {
            if (otherPeerRef.current != null) {
                otherPeerRef.current.send("Stayin Aliiiiiive !")
            }
            if (thisPeerRef.current != null) {
                thisPeerRef.current.send("Stayin Aliiiiiive !")
            }
        } catch (error) {
            setErrorMessages([...errorMessages, error.toString()])
        }
        setTimeout(() => {
            sendKeepAlive()
        }, 5000)
    }

    const call = (remotePeerId) => {
        try {
            var getUserMedia = navigator.getUserMedia
                || navigator.webkitGetUserMedia
                || navigator.mozGetUserMedia;
/*
            let constraints = {
                facingMode: "environment",
                width: { ideal: 4096, max: 4096 },
                height: { ideal: 2160, max: 2160 },
                framerate: { ideal: 10 }
            }*/
            let constraints = {
                facingMode: "environment",
                width: { min: 640 },
                height: { min: 480, max: 1080 }
            }

            getUserMedia({
                video: constraints, audio: false
            },
                (mediaStream) => {
                    currentUserVideoRef.current.srcObject = mediaStream;
                    currentUserVideoRef.current.play();

                    const call = peerInstance.current.call(remotePeerId, mediaStream, { sdpTransform: (sdp) => {
                        //setErrorMessages([...errorMessages, transformSdp(sdp)])
                        return transformSdp(sdp)
                    }})
                    let thisPeerTmp = peerInstance.current.connect(remotePeerId);
                    try {
                        thisPeerTmp.on("data", (data) => {
                            if (data == "end") {
                                thisPeerRef.current.close();
                                if (peerInstance.current != null) {
                                    peerInstance.current.destroy();
                                }
                                console.log("the caller ended the call", data);
                                setErrorMessages([...errorMessages, "Session ended"])
                                //endCall()
                            }
                        });
                        thisPeerRef.current = thisPeerTmp

                        setStreamIsActive(true)
                        call.on('stream', (remoteStream) => {
                            //remoteVideoRef.current.srcObject = remoteStream
                            //remoteVideoRef.current.play();
                        });

                        sendKeepAlive()
                    } catch (err) {
                        setErrorMessages([...errorMessages, "Can't find the desktop you are tying to connect to. try scanning his QR code again."])
                    }
                },
                (err) => {
                    console.error("Failed to get local stream", err);
                    setErrorMessages([...errorMessages, err.toString()])
                }
            );
        } catch (error) {
            console.error(error);
            setErrorMessages([...errorMessages, error.toString()])
        }
    }

    const endCall = () => {
        try {
            if (otherPeerRef.current != null) {
                console.log("Sending close to otherPeed")
                otherPeerRef.current.send("end");
            }
            if (thisPeerRef.current != null) {
                console.log("Sending close to thisPeer")
                thisPeerRef.current.send("end");
            }
            if (peerInstance.current != null) {
                console.log("Destorying peer")
                peerInstance.current.destroy();
            }

            const tracks = currentUserVideoRef.current.srcObject.getTracks();
            tracks.forEach((track) => {
                track.stop();
            });
            currentUserVideoRef.current.srcObject = null;
        } catch (err) {
            setErrorMessages([...errorMessages, err.toString()])
        }

        setStreamIsActive(false)
        //iniPeer()
    }

    const handleScan = (data) => {
        if (data != null) {
            //setRemotePeerIdValue(data.text)
            call(data.text)
            setStartScan(false)
        }
    }
    const handleError = (err) => {
        console.error(err)
        setErrorMessages([...errorMessages, err.toString()])
    }

    return (
        <PageWithNavigation selected="mobile" component={
            <div class="d-flex flex-column" >
                <ErrorMessages errorMessages={errorMessages} />
                <div class="d-flex flex-column scan-code-area box-container col-md">
                    <h2 class="m-4">Mobile link</h2>
                    {streamIsActive ? (
                        <div class="d-flex flex-column">
                            <p>Press the button below to end the session.</p>
                            <div class="mb-4">
                                <button class="wide" onClick={() => endCall()}>End session</button>
                            </div>
                        </div>) : (
                        <div class="d-flex flex-column">
                            <p>Press the connect button to use this phone as a camera.</p>
                            <div class="mb-4">
                                <button class="wide" onClick={() => call(remotePeerIdValue)}>Connect</button>
                            </div>
                        </div>
                    )}

                    {/*
                    {
                        startScan && (<QrReader
                            delay={100}
                            style={{ width: "100%" }}
                            onError={handleError}
                            onScan={handleScan}
                            constraints={{
                                audio: false,
                                video: { facingMode: "environment" }
                            }}
                        />)
                    }
                    
                */}
                </div>
                <video class={"phone-setup-video"} ref={currentUserVideoRef} muted autoplay playsinline />
            
                {/*<ReactPlayer className={"phone-setup-video" + ("opacity-0")}  volume={0} playing={true} muted={true} url={currentUserVideoRef.current} width="100%" height="100%" />*/}
                </div>
        } />
    );
}
