import { useEffect, useState } from "react"
import Loading from "../../Commons/Loading/Loading"
import UserName from "../../Commons/UserName"
import Button from "../../Commons/Buttons"
import { gameModesInfo } from "../../Commons/GameModes"
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore"
import { db } from "../../Commons/Firebase"

const deleteLobbies = async (list) => {
    list.forEach(async (l) => {
        console.log(l)
        await deleteDoc(doc(db, "public-lobbies", l.roomId));
    })
}

export default function PublicLobbies(props) {
    const [publicLobbies, setPublicLobbies] = useState(false)

    useEffect(() => {
        updatePublicLobbies()
    }, [])

    const updatePublicLobbies = async () => {
        if (publicLobbies !== false) { return }
        const lobbiesSnapshot = await getDocs(collection(db, 'public-lobbies'));
        const lobbiesList = lobbiesSnapshot.docs.map(doc => doc.data());
        console.log(lobbiesList)
        let availableLobbies = []
        let lobbiesToRemove = []
        lobbiesList.forEach((l) => {
            let time = getMinutesBetweenDates(Date.now() / 1000, l.creationTime)
            l.elapsedTime = time
            if (time > 20) {
                lobbiesToRemove.push(l)
            } else {
                availableLobbies.push(l)
            }
        })
        setPublicLobbies(availableLobbies)

        // delete old lobbies
        deleteLobbies(lobbiesToRemove)
    }

    function getMinutesBetweenDates(startDate, endDate) {
        const diff = startDate - endDate;
        return Math.floor(diff / 60);
    }

    return (<div class="d-flex flex-column col-md-6 box-container">
        <h2>Public lobbies</h2>
        <div class="lobbies">
            {publicLobbies === false && (<Loading />)}
            {publicLobbies.length === 0 && (<p>No Lobby found</p>)}
            {publicLobbies.length > 0 && (<div class="lobbies-list hide-scrollbar">
                {publicLobbies.map((l) => {
                    return (<PublicLobby lobby={l} />)
                })}
            </div>)}
        </div>
    </div>)
}

function PublicLobby(props) {
    return (
        <div class="lobby d-flex flex-column">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <UserName name={props.lobby.hostName} />
                <p class="m-0">{props.lobby.elapsedTime}min ago</p>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
                {gameModesInfo[props.lobby.gameMode] && (<p class="m-0">{gameModesInfo[props.lobby.gameMode].title}</p>)}
                <a class="wide" href={"https://exalts-table.com/lobby/" + props.lobby.roomId}>Join</a>
            </div>
        </div>)
}