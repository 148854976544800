import { useEffect, useState } from "react"
import "./ErrorMessages.css"

export default function ErrorMessages(props) {
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        if (props.errorMessages.length > 0) {
            setErrorMessages([...errorMessages, props.errorMessages.slice(-1)])
        }
    }, [props.errorMessages])

    if (errorMessages.length <= 0) { return }

    return (
        <div class="error-messages">
            <div class="d-flex flex-column">
                {errorMessages.map((message, i) => {
                    return (
                        <div class="d-flex flex-row justify-content-between shadowed" key={i + ":" + message}>
                            <p>{message}</p>
                            <img src={require('../Game/Icons/x.png')} onClick={() => {
                                let tmp = [...errorMessages]
                                tmp.splice(i, 1)
                                setErrorMessages(tmp)
                            }} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}