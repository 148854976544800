import { useState, useEffect, useLayoutEffect } from 'react'
import './Tumulte.css'
import React from 'react'
import Button from '../../Commons/Buttons'
import Loading from '../../Commons/Loading/Loading'

export default function TumulteSection(props) {
    const setPlayerPosition = (type, value) => {
        props.setPlayerPosition(type, value)
    }

    if (!props.playerRefIndexes.tumulteSection) {
        return(<div class="tumulte-section d-flex flex-row justify-content-center"><Loading /></div>)
    }

    const player1 = props.playersData[props.playerRefIndexes.tumulteSection[0]]
    const player2 = props.playersData[props.playerRefIndexes.tumulteSection[1]]
    return (
        <div class="tumulte-section d-flex flex-row justify-content-center">
            <TumulteStart value={0} />
            <TumulteCard value={1} tumulteData={props.globalData.tumulteCards[0]} />
            <TumulteCard value={3} tumulteData={props.globalData.tumulteCards[1]} />
            <TumulteCard value={5} tumulteData={props.globalData.tumulteCards[2]} />
            <TumulteStart value={7} />
            <div class="player-tokens-area">
                <HeroToken isPlayer={true} tokenColor={player1.faction} value={player1.tumultePositions[0]} setPlayerPosition={setPlayerPosition}/>
                <CompanionToken isPlayer={true} tokenColor={player1.faction} value={player1.tumultePositions[1]} setPlayerPosition={setPlayerPosition} />
                <HeroToken isPlayer={false} tokenColor={player2.faction} value={player2.tumultePositions[0]} setPlayerPosition={setPlayerPosition} />
                <CompanionToken isPlayer={false} tokenColor={player2.faction} value={player2.tumultePositions[1]} setPlayerPosition={setPlayerPosition} />
            </div>
        </div>
    )

    function TumulteStart(props) {
        const value = props.value
        const Image = React.memo(function Image({ src, className }) {
            return <img src={src} className={className} />;
        });
        return (
            <div class="tumulte-start">
                {<Image className={(value == 0 ? "" : "") + (" tumulte-card-image shadowed")} src={require(value == 0 ? './TumulteCards/hero-start.png' : './TumulteCards/companion-start.png')} />}
            </div>
        )
    }
}

function TumulteCard(props) {
    const tumulteData = props.tumulteData

    return (
        <div class="tumulte-card">
            <Image className={(tumulteData.hasBeenRevealed ? "hide animated " : "visible ") + "tumulte-card-image card-back shadowed"} src={require('./TumulteCards/tumulte-back.png')} />
            <Image className={(tumulteData.hasBeenRevealed ? "visible animated " : "hide ") + ("tumulte-card-image ")} src={require('./TumulteCards/' + tumulteData.data.image)} />
        </div>
    )
}    
const Image = React.memo(function Image({ src, className }) {
    return <img src={src} className={className} />;
});

function HeroToken(props) {
    return (
        <PlayerToken type="hero" isPlayer={props.isPlayer} color={props.tokenColor} value={props.value} setPlayerPosition={props.setPlayerPosition} />
    )
}

function CompanionToken(props) {
    return (
        <PlayerToken type="companion" isPlayer={props.isPlayer} color={props.tokenColor} value={props.value} setPlayerPosition={props.setPlayerPosition} />
    )
}


function PlayerToken(props) {
    const Image = React.memo(function Image({ src, className }) {
        return <img src={src} className={className} />;
    });
    var art = ""
    const isHero = props.type == "hero"
    if (isHero) {
        art = "PlayerIcons/player-" + props.color + "-1.png"
    } else {
        art = "PlayerIcons/player-" + props.color + "-2.png"
    }

    return (
        <div class={(props.isPlayer ? "player " : "opponent ") + (isHero ? "hero " : "companion ") + "player-token" + (" position-" + props.value)}>
            <div class={"player-token-offset "} >
                {props.isPlayer && (
                    <div class="position-relative token-move-buttons">
                        <div class="d-flex flex-row">
                            <Button onClick={() => props.setPlayerPosition(props.type, props.value - 1)}><img src={require("../Icons/left.png")} /></Button>
                            <Button onClick={() => props.setPlayerPosition(props.type, props.value + 1)}><img src={require("../Icons/right.png")} /></Button>
                        </div>
                    </div>
                )}
                {<Image className="tumulte-position-token glow" src={require('./' + art)} />}
            </div>
        </div>
    )
}

export const TumulteMemo = React.memo(({ globalData, playersData, setPlayerPosition, playersRef, isSpectator, playerRefIndexes }) => {
    console.log("Tumulte rerendered")
    return (<TumulteSection globalData={globalData} playersData={playersData} playersRef={playersRef} setPlayerPosition={setPlayerPosition} isSpectator={isSpectator} playerRefIndexes={playerRefIndexes} />)
})

