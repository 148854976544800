import React, { useRef, useState } from "react"
import "./PowerCounter.css"
import { uuidv4 } from "../../QRCodeRecognition/Functions"
import Button from "../../Commons/Buttons"

export default function PowerCounter(props) {
    if (!props.playerRefIndexes.tumulteSection) {
        return (<div></div>)
    }

    const player1 = props.playersGameData[props.playerRefIndexes.tumulteSection[0]]
    //const player2 = props.playersGameData[props.playerRefIndexes.tumulteSection[1]]


    function add(expedition, counter) {
        let tmp = { ...player1 }
        let powerCounters = tmp.powerCounters
        if (counter == "all") {
            powerCounters[expedition].forest += 1
            powerCounters[expedition].mountain += 1
            powerCounters[expedition].water += 1
        } else {
            powerCounters[expedition][counter] += 1
        }
        props.setPlayersGameData(tmp)
    }

    function remove(expedition, counter) {
        let tmp = { ...player1 }
        let powerCounters = tmp.powerCounters
        if (counter == "all") {
            if (powerCounters[expedition].forest >= 1) {
                powerCounters[expedition].forest -= 1
            }
            if (powerCounters[expedition].mountain >= 1) {
                powerCounters[expedition].mountain -= 1
            }
            if (powerCounters[expedition].water >= 1) {
                powerCounters[expedition].water -= 1
            }
        } else {
            if (powerCounters[expedition][counter] >= 1) {
                powerCounters[expedition][counter] -= 1
            }
        }
        props.setPlayersGameData(tmp)
    }

    function reset(expedition) {
        let tmp = { ...player1 }
        let powerCounters = tmp.powerCounters
        powerCounters[expedition].forest = 0
        powerCounters[expedition].mountain = 0
        powerCounters[expedition].water = 0
        props.setPlayersGameData(tmp)
    }

    if (props.isSpectator) {
        return (<div class="power-counters">
            {props.playerRefIndexes.tumulteSection.map((id) => {
                let opponentCounters = {
                    hero: props.playersGameData[props.playersGameData[id].powerCountersOpponentsId.hero].powerCounters.hero,
                    companion: props.playersGameData[props.playersGameData[id].powerCountersOpponentsId.companion].powerCounters.companion
                }
                return (<div>
                    <Counters player={props.playersRef[1].id == id ? "player" : "opponent"} expedition="hero" data={props.playersGameData[id]} opponentCounters={opponentCounters} add={add} reset={reset} remove={remove} />
                    <Counters player={props.playersRef[1].id == id ? "player" : "opponent"} expedition="companion" data={props.playersGameData[id]} opponentCounters={opponentCounters} add={add} reset={reset} remove={remove} />
                </div>)
            })}
        </div>)
    }
    return (<div class="power-counters">
        {props.playerRefIndexes.tumulteSection.map((id) => {
            let opponentCounters = {
                hero: props.playersGameData[props.playersGameData[id].powerCountersOpponentsId.hero].powerCounters.hero,
                companion: props.playersGameData[props.playersGameData[id].powerCountersOpponentsId.companion].powerCounters.companion
            }
            return (<div>
                <Counters player={props.playersRef[0].id == id ? "player" : "opponent"} expedition="hero" data={props.playersGameData[id]} opponentCounters={opponentCounters} add={add} reset={reset} remove={remove} />
                <Counters player={props.playersRef[0].id == id ? "player" : "opponent"} expedition="companion" data={props.playersGameData[id]} opponentCounters={opponentCounters} add={add} reset={reset} remove={remove} />
            </div>)
        })}
    </div>)
}

export function Counters(props) {
    return (
        <div class={"power-counter d-flex flex-row shadowed " + props.player + " " + props.expedition + (props.className ? props.className : "")} >
            <div class="wrapper"></div>
            <Counter type="forest" player={props.player} expedition={props.expedition} data={props.data} opponentCounters={props.opponentCounters} add={props.add} remove={props.remove} />
            <Counter type="mountain" player={props.player} expedition={props.expedition} data={props.data} opponentCounters={props.opponentCounters} add={props.add} remove={props.remove} />
            <Counter type="water" player={props.player} expedition={props.expedition} data={props.data} opponentCounters={props.opponentCounters} add={props.add} remove={props.remove} />
            {
                props.player == "player" && (<div class="counter all">
                    <Button class="plus" onClick={() => props.add(props.expedition, "all")}>+</Button>
                    <Button class="reset" onClick={() => props.reset(props.expedition)}>x</Button>
                    <Button class="minus" onClick={() => props.remove(props.expedition, "all")}>-</Button>
                </div>)
            }
        </div >
    )

    function Counter(props) {
        let player = props.player
        let expedition = props.expedition
        let type = props.type
        return (
            <div class={"counter d-flex flex-row justify-content-around align-items-center " + type + (props.data.powerCountersActive[expedition][type] ? "" : " inactive") + (props.opponentCounters[expedition][type] < props.data.powerCounters[expedition][type] ? " winning" : "")} >
                <div class="background"></div>
                <img src={require('./Assets/' + type + '.png')} />
                <p>{props.data.powerCounters[expedition][type]}</p>
                {player == "player" && (<Button class="plus" onClick={() => props.add(expedition, type)}>+</Button>)}
                {player == "player" && (<Button class="minus" onClick={() => props.remove(expedition, type)}>-</Button>)}
            </div>
        )
    }
}

export const PowerCounterMemo = React.memo(({ playersGameData, playersRef, setPlayersGameData, playerRefIndexes, isSpectator }) => {
    console.log("Counters rerendered")
    return (<PowerCounter playersGameData={playersGameData} playersRef={playersRef} setPlayersGameData={setPlayersGameData} playerRefIndexes={playerRefIndexes} isSpectator={isSpectator} />)
})