import React, { useEffect, useLayoutEffect, useState } from 'react';
import './Chat.css'
import Button from '../../../Commons/Buttons';

export default function Chat(props) {
    const textareaRef = React.useRef(null);
    const [textMessage, setTextMessage] = React.useState("");
    const [hasUnreadMessage, setHasUnreadMessage] = useState(false)
    const onChange = (event) => {
        let text = event.target.value
        if (text.length < 150) {
            setTextMessage(event.target.value)
        }
    };

    useLayoutEffect(() => {
        if (!props.isVisible && props.chat.messages.length > 2) {
            setHasUnreadMessage(true)
        }
    }, [props.chat.messages.length])

    useLayoutEffect(() => {
        if (props.isVisible) {
            setHasUnreadMessage(false)
        }
    }, [props.isVisible])

    useLayoutEffect(() => {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }, [textMessage]);

    return (<div class="h-100">
        {hasUnreadMessage && (<div class="unread-notification"><p class="m-0">!</p></div>)}
        <div class="chat d-flex flex-column">
        <div class="top-gradient"></div>
            <div class="messages hide-scrollbar">
                <div class="messages-wrapper">
                    {props.chat.messages.map((message, i) => {
                        return (<Message key={i} message={message} playerId={props.playerId} />)
                    })}
                </div>
            </div>
            <div class="message-editor">
                <div class="d-flex flex-row input-with-button">
                    <textarea
                        onChange={onChange}
                        ref={textareaRef}
                        value={textMessage}
                        placeholder='Send message...'
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey && "form" in e.target) {
                                e.preventDefault();
                                if (textMessage.length > 0) {
                                    props.sendMessage(textMessage)
                                }
                                setTextMessage("")
                            }
                        }}
                    />

                    <Button class="" onClick={() => {
                        props.sendMessage(textMessage)
                        setTextMessage("")
                    }}><img src={require('../../Icons/next.png')} /></Button>
                </div>
            </div>
        </div>
    </div>)
}

function Message(props) {
    return (<div class={"message d-flex flex-column align-items-start" + (props.playerId == props.message.sender.id ? " you" : " other")}>
        <p class="message-sender">{props.message.sender.name}</p>
        <p class="message-content">{props.message.message}</p>
    </div>)
}