import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

//Import all translation files
import English from "./English.json";
import French from "./French.json";
import Italian from "./Italian.json";
import Deutsch from "./Deutsch.json";
import Spanish from "./Spanish.json";

const resources = {
    en: {
        translation: English,
    },
    fr: {
        translation: French,
    },
    it: {
        translation: Italian,
    },
    de: {
        translation: Deutsch,
    },
    es: {
        translation: Spanish,
    },
}

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        supportedLngs: ['de', 'en', 'fr', 'it', 'es'],
        fallbackLng: "en", //default language
    });

export default i18next;