import PageWithNavigation from "../../Commons/PageWithNavigation"
import { useLanguageContext } from "../../Commons/Translations/languageContext";
import "./Help.css"
import Carousel from 'react-bootstrap/Carousel';

export default function Help(props) {
    const { t } = useLanguageContext()
    return (
        <PageWithNavigation selected="help" component={
            <div class="d-flex flex-column justify-content-around help-section">
                <Carousel interval={null} className={""} >
                    <Carousel.Item>
                        <img src={require("./Assets/Welcome.jpg")} />
                        <Carousel.Caption>
                            <h3>{t("help.welcome.title")}</h3>
                            <p>{t("help.welcome.content")}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    {/* 
                    <Carousel.Item>
                        <img src={require("./Assets/Scan.gif")} />
                        <Carousel.Caption>
                            <h3>QR Code detection (playtest cards only)</h3>
                            <p>Put your card close to your camera to scan it and show your opponent a clear version of it. When the scan is successfull, a white flash on your camera will appear to let you know.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    */}
                    <Carousel.Item>
                        <img src={require("./Assets/Search.jpg")} />
                        <Carousel.Caption>
                            <h3>{t("help.card_search.title")}</h3>
                            <p>{t("help.card_search.content")}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={require("./Assets/Tumulte.jpg")} />
                        <Carousel.Caption>
                            <h3>{t("help.tumult.title")}</h3>
                            <p>{t("help.tumult.content")}</p> </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={require("./Assets/Counters.jpg")} />
                        <Carousel.Caption>
                            <h3>{t("help.expedition.title")}</h3>
                            <p>{t("help.expedition.content")}</p> </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={require("./Assets/FirstPlayer.jpg")} />
                        <Carousel.Caption>
                            <h3>{t("help.first_player.title")}</h3>
                            <p>{t("help.first_player.content")}</p></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={require("./Assets/Zoom.jpg")} />
                        <Carousel.Caption>
                            <h3>{t("help.zoom.title")}</h3>
                            <p>{t("help.zoom.content")}</p></Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        } />
    )
}

/*

welcome




QR code detection
move tumulte
change first player



*/