import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBTfO4MX6zxb8TE5k07ovYCSzgFGMoNzcE",
  authDomain: "exalts-table.firebaseapp.com",
  projectId: "exalts-table",
  storageBucket: "exalts-table.appspot.com",
  messagingSenderId: "548746703885",
  appId: "1:548746703885:web:4392dfb2166eb312c75144"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);