import { Navbar } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import './Navigation.css'
import DarkMode from '../Commons/DarkMode/DarkMode';
import LanguageSelect from '../Commons/Translations/LanguageSelect';
import { useLanguageContext } from '../Commons/Translations/languageContext';

export default function Navigation(props) {
    const { t } = useLanguageContext() 
    // add selected props
    return (
        <Navbar className="navigation light-shadowed" expand="lg">
            <div class="background"></div>
            <a href="/" class="me-auto">
                <div class="d-flex flex-row">
                    <img class="app-icon" src={require('../Game/Icons/app-icon.png')} />
                    <h3>Exalts Table</h3>
                </div>
            </a>
            <Navbar.Toggle aria-controls="main-navbar-nav" />
            <Navbar.Collapse id="main-navbar-nav">
                <Nav className="ms-auto buttons">
                    <Nav.Link className={props.selected == "play" ? "selected" : ""} href="/play"><div class={"d-flex flex-row align-items-center"}><img src={require('./Icons/play.png')} /><p class="d-flex align-items-center">{t("navigation.play")}</p></div></Nav.Link>
                    <Nav.Link className={props.selected == "help" ? "selected" : ""} href="/help"><div class={"d-flex flex-row align-items-center"}><img src={require('./Icons/help.png')} /><p class="d-flex align-items-center">{t("navigation.guide")}</p></div></Nav.Link>
                    <Nav.Link href="https://discord.gg/ARcY9xeapu" target="_blank"><div class={"d-flex flex-row align-items-center"}><img src={require('./Icons/discord.png')} /><p class="d-flex align-items-center">{t("navigation.discord")}</p></div></Nav.Link>
                    <Nav.Link href="https://www.patreon.com/user?u=91929461" target="_blank"><div class={"d-flex flex-row align-items-center"}><img src={require('./Icons/patreon.png')} /><p class="d-flex align-items-center">{t("navigation.patreon")}</p></div></Nav.Link>
                    <Nav.Link className={props.selected == "about" ? "selected" : ""} href="/about"><div class={"d-flex flex-row align-items-center"}>{/*<img src={require('./Icons/play.png')} />*/}<p class="d-flex align-items-center">{t("navigation.about")}</p></div></Nav.Link>
                    <DarkMode />
                    <LanguageSelect />
                </Nav>
            </Navbar.Collapse>

        </Navbar>
    )
}