import './DarkMode.css'
import { ChangeEventHandler, useState } from "react";

const setDark = () => {
  localStorage.setItem("theme", "dark");
  document.documentElement.setAttribute("data-theme", "dark");
};

const setLight = () => {
  localStorage.setItem("theme", "light");
  document.documentElement.setAttribute("data-theme", "light");
};

const storedTheme = localStorage.getItem("theme");

const prefersDark =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const defaultDark = storedTheme === "dark" || (storedTheme === null && prefersDark);

if (defaultDark) {
  setDark();
}

const toggleTheme = (isDark) => {
  if (!isDark) {
    setDark();
  } else {
    setLight();
  }
};

const DarkMode = () => {
  const [isDark, setIsDark] = useState(defaultDark)
  return (
    <div className="toggle-theme-wrapper left-side-button button" onClick={() => {
      toggleTheme(isDark)
      setIsDark(!isDark)
    }}>
      <img src={require('./light.png')} />
      <img src={require('./dark.png')} />
    </div>
  );
};

export default DarkMode;