import { useLanguageContext } from "./languageContext";

const LanguageSelect = () => {
    const { i18n, languages, onClickLanguageChange } = useLanguageContext();
    return (
        <div className="lang-select-wrapper">
            <select
                className="lang-select"
                onChange={onClickLanguageChange}
            >
                {Object.keys(languages).map((lng) => {
                    if (lng == i18n.language) {
                        return (<option key={languages[lng].nativeName}
                            value={lng}
                            selected >
                            {languages[lng].nativeName}
                        </option>)
                    } else {
                        return (<option key={languages[lng].nativeName}
                            value={lng} >
                            {languages[lng].nativeName}
                        </option>)
                    }
                }
                )}
            </select>
        </div>
    );
};

export default LanguageSelect;