import './PopUpPanel.css'

export default function PopUpPanel(props) {
    return (
        <div class="popup-panel">
            <div class="content-area">
                {props.component}
            </div>
        </div>
    )
}