import transform, { SessionDescription } from 'sdp-transform';

export const transformSdp = (sdp) => {
    return sdp
    let array = sdp.split('\n')
    console.log(array)
    let final = ""
    let i = 0
    array.forEach((s) => {
        if (s.includes("m=video")) {
            let line = setDefaultCodec(s, "96")
            line = setDefaultCodec(line, "98")
            final += line
        } else {
            final += s
        }
        i += 1
        if (i != s.lenght) {
            final += "\n"
        }
    })
    console.log(sdp)
    console.log("vs")
    console.log(final)
    return final;
    const parsed = transform.parse(sdp);

    parsed.media.forEach(media => {
        if (media.type === 'audio') {
            //transformAudio(media);
        } else {
            transformVideo(media);
        }
    });
    let res = transform.write(parsed)
    console.log(res)
    return res;
};

const setDefaultCodec = (mLine, payload) => {
    var elements = mLine.split(' ');
  
    // Just copy the first three parameters; codec order starts on fourth.
    var newLine = elements.slice(0, 3);
  
    // Put target payload first and copy in the rest.
    newLine.push(payload);
    for (var i = 3; i < elements.length; i++) {
      if (elements[i] !== payload) {
        newLine.push(elements[i]);
      }
    }
    return newLine.join(' ');
  }

const getPayloads = (payloads, codecs) => {
    return payloads
        ?.split(' ')
        .filter(value => codecs.includes(+value))
        .join(' ');
};

const transformAudio = (media) => {
    /*
     * 111 - opus 48000, encoding - 2
     * 103 - isac 16000
     * 63 - red 48000, encoding - 2
     */

    const audioCodecs = [111, 103, 63];

    media.payloads = getPayloads(media.payloads, audioCodecs);
    media.rtp = media.rtp.filter(({ payload }) => audioCodecs.includes(payload));
};

const transformVideo = (media) => {
    /*
     * 96 - VP8
     * 98 - VP9
     * 102 - H264
     */
    const videoCodecs = [96, 97, 106, 107, 108, 109, 98, 99, 116, 117, 118];
    // iphone: 96 97 98 99 100 101
    media.payloads = getPayloads(media.payloads, videoCodecs);
    media.rtp = media.rtp.filter(({ payload }) => videoCodecs.includes(payload));
    media.rtcpFb = media.rtcpFb?.filter(({ payload }) => videoCodecs.includes(payload));
    media.fmtp = media.fmtp.filter(({ config }) => videoCodecs.includes(Number(config.split('=')[1])));
};