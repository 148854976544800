import './FullScreenPanel.css'

export default function FullScreenPanel(props) {
    return(
        <div class="fullscreen-panel">
            <div class="dismiss-area" onClick={props.hidePanel}></div>
            <div class="fullscreen-panel-content d-flex flex-column shadowed">
                <div class="title-area d-flex flex-row justify-content-between">
                    <h2>{props.title}</h2>
                    <img class="close-button" src={require('../Game/Icons/x.png')} onClick={props.hidePanel} />
                </div>
                <div class="content-area">
                    {props.component}
                </div>
            </div>
        </div>
    )
}