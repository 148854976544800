import { useLanguageContext } from '../Commons/Translations/languageContext';
import Footer from './Footer'
import './Home.css'
import Navigation from './Navigation'
import Patreon from './Patreon'

export default function Home() {
    const { t } = useLanguageContext();
    return (
        <div class="home w-100 d-flex flex-column">
            <div class="intro w-100 page-with-navigation-content pb-4 d-flex flex-column justify-content-between">
                <Navigation selected="home" />
                <div class="d-flex flex-row justify-content-center h1-group h-100 align-items-center mx-auto">
                    <h1 class=""><span class="bold">{t("home.title_part1")}</span>{t("home.title_part2")}</h1>
                </div>
                <img src={require('./Assets/Intro-light.jpg')} class="intro-image light-shadowed" />
            </div>
            <div class="functionalities w-100">
                <div class="description">
                    <p>{t("home.intro")}</p>
                </div>

                <h2>{t("home.functionnalities.title")}</h2>
                <div class="functionality light-shadowed right">
                    <div class="d-flex flex-column justify-content-center">
                        <h3>{t("home.functionnalities.video.title")}</h3>
                        <p dangerouslySetInnerHTML={{ __html: t("home.functionnalities.video.content") }}></p>
                    </div>
                    <img class="shadowed" src={require('./Assets/Video.jpg')} />
                </div>
                <div class="functionality light-shadowed">
                    <img class="shadowed" src={require('./Assets/functionality4.jpg')} />
                    <div class="d-flex flex-column justify-content-center">
                        <h3>{t("home.functionnalities.virtual.title")}</h3>
                        <p dangerouslySetInnerHTML={{ __html: t("home.functionnalities.virtual.content") }}></p>
                    </div>
                </div>
                {/*
                <div class="functionality shadowed">
                    <img class="shadowed" src={require('./Assets/Scanned.jpg')} />
                    <div class="d-flex flex-column justify-content-center">
                        <h3>Card identification (WIP)</h3>
                        <p>Put a card in front of your camera to let the app scan the QR Code and send to your opponent a clean image of the card (only works with play test cards currently). </p>
                    </div>
                </div>
                 */}
                <div class="functionality light-shadowed right">
                    <div class="d-flex flex-column justify-content-center">
                        <h3>{t("home.functionnalities.tumult.title")}</h3>
                        <p dangerouslySetInnerHTML={{ __html: t("home.functionnalities.tumult.content") }}></p></div>
                    <img class="shadowed" src={require('./functionality3.png')} />
                </div>

            </div>
            <Patreon />
            <Footer />
        </div>
    )
}