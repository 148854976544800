import jsQR from 'jsqr-es6'
import { PNG } from 'pngjs/browser';
import { Buffer } from 'buffer';
import { BarcodeDetector as BarcodeDetectorPolyfill } from "barcode-detector/pure";
import { useEffect, useRef } from 'react';
import { useLanguageContext } from '../Commons/Translations/languageContext';

/*
  params: imageBase64, type string
  convert image to base64 using rn-fetch-blob (https://github.com/joltup/rn-fetch-blob)
*/
export const decodeQRCodeFromImageJSQR = async (image) => {
  return new Promise(async (resolve, reject) => {
    //const png = PNG.sync.read(Buffer.from(dataUri.slice('data:image/png;base64,'.length), 'base64'));
    //const code = jsQR(Uint8ClampedArray.from(png.data), png.width, png.height);
    //let imageBitmap = createImageBitmap(image.imageData)
    const barcodeDetector = new BarcodeDetectorPolyfill({
      formats: ["qr_code"],
    });

    /*const code = jsQR(image.imageData.data, image.width, image.height, { inversionAttempts: "dontInvert" })
    if (code) {
      return code.data
    }
    return null*/
    try {
      const barcodes = await barcodeDetector.detect(image.imageData)
      if (barcodes.length == 0) {
        resolve(null)
        /*
        const code = jsQR(image.imageData.data, image.width, image.height, { inversionAttempts: "dontInvert" })
        if (code) {
          return code.data
        }
        return null*/
      } else {
        console.log(barcodes[0].rawValue)
        resolve(barcodes[0].rawValue)
      }
    } catch (err) {
      console.error(err);
      resolve(null)
    };
  })

}

export const decodeQRCodeFromImageBarcodeDetector = async (canvas) => {
  return new Promise(async (resolve, reject) => {
    const barcodeDetector = new BarcodeDetectorPolyfill({
      formats: ["qr_code"],
    });

    try {
      console.log("try scan")
      const barcodes = barcodeDetector.detect(canvas).then((barcodes) => {
        console.log("scan completed")
        if (barcodes.length == 0) {
          resolve(null)
        } else {
          console.log(barcodes)
          resolve(barcodes)
        }
      })
    } catch (err) {
      console.error(err);
      resolve(null)
    };
  })

}

const test = async (resolve, images, i) => {
  let code = await decodeQRCodeFromImageJSQR(images[i])
  console.log("step")
  if (code == null) {
    if (i + 1 >= images.length) { resolve(null) } else {
      test(resolve, images, i + 1)
    }
  } else {
    resolve(code)
  }
}

export const decodeQRCodeFromImageArray = async (images) => {
  let code = null
  let i = 0
  //while (i < images.length && code == null) {
  /*for (; ;) {
    code = await decodeQRCodeFromImageJSQR(images[i])
    i++;
    if (i >= images.length || code != null) {
      break;
    }
  }*/
  /*
  console.log("starting")
  code = await new Promise((res, rej) => test(res, images, 0));
  console.log("finished")
*/
  while (i < images.length && code == null) {
    code = await decodeQRCodeFromImageJSQR(images[i])
    i++;
    if (i >= images.length || code != null) {
      break;
    }
  }
  /*
  if (!("BarcodeDetector" in window)) {
    console.log("Barcode Detector is not supported by this browser.");
    while (i < images.length && code == null) {
      code = await decodeQRCodeFromImageJSQR(images[i])
      i++;
    }
  } else {
    console.log("Barcode Detector supported");
    while (i < images.length && code == null) {
      code = await decodeQRCodeFromImageBarcodeDetector(images[i])
      i++;
    }
  }*/
  console.log("code found try " + i)
  return code
}

export const sharpen = (ctx, w, h, mix) => {
  var x, sx, sy, r, g, b, a, dstOff, srcOff, wt, cx, cy, scy, scx,
    weights = [0, -1, 0, -1, 5, -1, 0, -1, 0],
    katet = Math.round(Math.sqrt(weights.length)),
    half = (katet * 0.5) | 0,
    dstData = ctx.createImageData(w, h),
    dstBuff = dstData.data,
    srcBuff = ctx.getImageData(0, 0, w, h).data,
    y = h;
  while (y-- && y > 0) {
    x = w;
    while (x-- && x > 0) {
      sy = y;
      sx = x;
      dstOff = (y * w + x) * 4;
      r = 0;
      g = 0;
      b = 0;
      a = 0;
      if (x > 0 && y > 0 && x < w - 1 && y < h - 1) {
        for (cy = 0; cy < katet; cy++) {
          for (cx = 0; cx < katet; cx++) {
            scy = sy + cy - half;
            scx = sx + cx - half;

            if (scy >= 0 && scy < h && scx >= 0 && scx < w) {
              srcOff = (scy * w + scx) * 4;
              wt = weights[cy * katet + cx];

              r += srcBuff[srcOff] * wt;
              g += srcBuff[srcOff + 1] * wt;
              b += srcBuff[srcOff + 2] * wt;
              a += srcBuff[srcOff + 3] * wt;
            }
          }
        }

        dstBuff[dstOff] = r * mix + srcBuff[dstOff] * (1 - mix);
        dstBuff[dstOff + 1] = g * mix + srcBuff[dstOff + 1] * (1 - mix);
        dstBuff[dstOff + 2] = b * mix + srcBuff[dstOff + 2] * (1 - mix);
        dstBuff[dstOff + 3] = srcBuff[dstOff + 3];
      } else {
        dstBuff[dstOff] = srcBuff[dstOff];
        dstBuff[dstOff + 1] = srcBuff[dstOff + 1];
        dstBuff[dstOff + 2] = srcBuff[dstOff + 2];
        dstBuff[dstOff + 3] = srcBuff[dstOff + 3];
      }
    }
  }

  ctx.putImageData(dstData, 0, 0);
}


export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}

export const getCardFromCardData = function (card, lang) {
  // A METTRE EN COMMUN AVEC DECK SETUP
  let costs = [0, 0]
  let power = [0, 0, 0]

  const getNumbers = (str) => {
    var numb = str.match(/\d/g);
    numb = numb.join("");
    return numb
  }

  if (card["elements"]["MAIN_COST"]) {
    costs[0] = getNumbers(card["elements"]["MAIN_COST"])
  }
  if (card["elements"]["RECALL_COST"]) {
    costs[1] = getNumbers(card["elements"]["RECALL_COST"])
  }

  if (card["elements"]["FOREST_POWER"]) {
    power[0] = getNumbers(card["elements"]["FOREST_POWER"])
  }
  if (card["elements"]["MOUNTAIN_POWER"]) {
    power[1] = getNumbers(card["elements"]["MOUNTAIN_POWER"])
  }
  if (card["elements"]["OCEAN_POWER"]) {
    power[2] = getNumbers(card["elements"]["OCEAN_POWER"])
  }

  let faction = card["mainFaction"] == "LY" ? "LYRA" : card["mainFaction"] == "AX" ? "AXIOM" : card["mainFaction"] == "MU" ? "MUNA" : card["mainFaction"] == "OR" ? "ORDIS" : card["mainFaction"] == "YZ" ? "YZMIR" : card["mainFaction"] == "BR" ? "BRAVOS" : "NONE"

  return {
    name: card["name"][lang] ? card["name"][lang] : card["name"],
    image: card["imagePath"][lang] ? card["imagePath"][lang] : card["imagePath"],
    images: card["imagePath"][lang] ? card["imagePath"] : undefined,
    id: card["id"],
    type: card["type"] ? card["type"] : card["cardType"]["reference"],
    reference: card["reference"],
    manaCost: costs,
    power: power,
    rarity: card["rarity"],
    faction: faction,
    cardId: uuidv4()
  }
}

export const shuffle = (array) => {
  let currentIndex = array.length;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}

export const shuffleArray = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export const useHorizontalScroll = () => {
  const elRef = useRef()
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          /*behavior: "smooth"*/
        });
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, []);
  return elRef;
}

export const createEmptyVideoTrack = ({ width, height }) => {
  const canvas = Object.assign(document.createElement('canvas'), { width, height });
  canvas.getContext('2d').fillRect(0, 0, width, height);

  const stream = canvas.captureStream();
  const track = stream.getVideoTracks()[0];

  return Object.assign(track, { enabled: false });
};

export const createEmptyMediaStream = () => {
  const videoTrack = createEmptyVideoTrack({ width: 3, height: 7 });
  return new MediaStream([videoTrack]);
}

export function arraysEqual(a1, a2) {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) == JSON.stringify(a2);
}

export const pushToDict = (dictionary, key, value) => {
  dictionary[key] = value;
};