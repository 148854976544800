import { useState } from 'react'

export default function PopUpMessage(props) {
    const [isVisible, setIsVisible] = useState(true)
    return (
        <div class={"position-fixed top-0 start-50 shadowed pop-up-message d-flex flex-row" + (isVisible ? "" : " hide")}>
            {props.children}
            <img class="close-button" src={require('../Game/Icons/x.png')} onClick={() => {
                setIsVisible(false)
            }} />
        </div>
    )
}