import { useState } from "react"
import "./LeftBar.css"

export default function LeftBar(props) {
    const [hideLeftBar, setHideLeftBar] = useState(false)

    return (<div class={hideLeftBar ? "hide-left-bar" : ""}>
        <div class="left-bar-placeholder"></div>
        <div class="left-bar d-flex flex-column">
            <div class="show-hide-toggle" onClick={() => { setHideLeftBar(!hideLeftBar) }} >
                <img src={require('../Icons/' + (hideLeftBar ? "next.png" : "back.png"))} />
            </div>
            { props.component }
        </div>
    </div>)
}