import { useEffect, useState } from "react"
import PageWithNavigation from "../../Commons/PageWithNavigation"
import "./WaitingRoom.css"
import Form from 'react-bootstrap/Form';
import Game from "../../Game/Game"
import ConnectionHandlerHost from "./ConnectionHandlerHost";
import { gameModesInfo } from "../../Commons/GameModes";
import { useLanguageContext } from "../../Commons/Translations/languageContext";
import PublicLobbies from "./PublicLobbies";

export default function Lobby(props) {
    const { t } = useLanguageContext()
    const [isLobbyCreated, setIsLobbyCreated] = useState(false)
    const [selectedGameMode, setSelectedGameMode] = useState("_1v1_standard")
    const [gameModeInfo, setGameModeInfo] = useState(false)

    useEffect(() => {
        updateGameModeInfo("_1v1_standard")
    }, [])

    useEffect(() => {
        if (isLobbyCreated == false) {
            window.history.replaceState(null, "Exalts Table", "/play")
            updateGameModeInfo("_1v1_standard")
        }
    }, [isLobbyCreated])

    function updateGameModeInfo(newValue) {
        setSelectedGameMode(newValue)
        let info = gameModesInfo[newValue]
        if (info) {
            setGameModeInfo({ ...info })
        }
    }

    if (isLobbyCreated) {
        return (
            <ConnectionHandlerHost setIsLobbyCreated={setIsLobbyCreated} gameModeInfo={gameModeInfo} />
        )
    }

    return (
        <PageWithNavigation selected="play" description={t("play.description")} component={
            <div class="d-flex flex-column justify-content-around">
                <div class="d-flex flex-row row justify-content-around position-relative">
                    <PublicLobbies />
                    <div class="vertical-divider"></div>
                    <div class="col-md-6 box-container">
                        <div class="d-flex flex-column gray-box">
                            <h2>{t("play.title")}</h2>
                            <p>{t("play.choose_mode")}</p>
                            <Form.Select aria-label="Default select example" onChange={(e) => {
                                updateGameModeInfo(e.target.value)
                            }}>
                                <option value="_1v1_standard">1v1 - Standard</option>
                                <option value="_1v1_standard_observer">1v1 - Standard with spectator (Beta)</option>
                                <option value="_draft">Draft (Beta)</option>
                                <option value="_2v2_standard" disabled>2v2 - Coming soon</option>
                            </Form.Select>

                            {gameModeInfo && (
                                <div class="d-flex flex-column">
                                    <p class="mt-4">{t("play.players")}: {gameModeInfo.players}</p>
                                    <p class="text-start">{gameModeInfo.description}</p>
                                </div>
                            )}
                            <button onClick={() => { setIsLobbyCreated(true) }}>{t("play.create_lobby_button")}</button>
                        </div>
                    </div>
                </div>
            </div>
        } />
    )
}