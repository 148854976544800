export default function Button(props) {
    return (
        <div class={"button " + (props.class ? props.class : "") + (props.selected ? " selected" : "")} onClick={props.onClick}>{props.children}</div>
    )
}

export function Switch(props) {
    return (
        <div class={"switch d-flex flex-row justify-content-between" + (props.isEnabled ? " enabled" : "")}>
            <p onClick={props.onClick}>{props.disabledMessage}</p>
            <p onClick={props.onClick}>{props.enabledMessage}</p>
            <Button onClick={props.onClick}>{props.isEnabled ? props.enabledMessage : props.disabledMessage}</Button>
        </div>
    )
} 