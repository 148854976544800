import { useEffect } from "react";
import DeckSetup from "./DeckSetup";
import { useParams, useSearchParams } from 'react-router-dom';

export default function DeckSetupLink(props) {
    const [searchParams] = useSearchParams();
    const decklist = searchParams.get("decklist");  
    return(<DeckSetup ajordatDecklist={decklist} />)
}

export function DeckSetupSecret(props) {
    return(<DeckSetup isSecret={true} />)
}